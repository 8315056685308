/* COULEURS */

$commerce_color: #7BC3DA;
$culture_color: #c9b6d8;
$education_color: #FF8761;
$loisirs_color: #FFC400;
$enfance_color: #38A4DD;
$sante_color: #6DC7BE;
$serv_public_color: #E78BAA;
$tourisme_color: #98C87D;
$transport_color: #8CA3BD;
$risques_color: #c66161;
$act_sport_color: #65a8a8;
$act_recr_color: #e5af73;
$lieux_tour_color: #c8c068;
$lieux_inter_color: #a89385;
$festival_color: #e5af73;

/* MIXINS */

@mixin gmap($width) {
	width: -moz-calc(960px - #{$width});
	width: -webkit-calc(960px - #{$width});
	width: calc(100% - #{$width});
	height: 100%;
}

/* bonsaï */

@mixin nbrPresent($color, $margin-top) {
	color: $color;
	width: 20px;
	height: 21px;
	float: right;
	line-height: 16px;
	margin-right: 5px;
	text-align: right;
	font-family: '__Titillium Web_5';
	margin-top: $margin-top;
}

/* bouquet */

@mixin nbrPresentBouquet($background-color, $color, $border-color) {
	background-color: $background-color;
	color: $color;
	border-color: $border-color;
}

@mixin heightDetails($height) {
	height: -moz-calc(674px - #{$height});
	height: -webkit-calc(674px - #{$height});
	height: calc(674px - #{$height});
}

/* horizontal */

@mixin liseret($color) {
	width: 100%;
	position: absolute;
	bottom : 80px;
	height: 3px;
	background-color: $color;
}

/* pastel */

@mixin nbrPresentPastel($color) {
	margin-top: 5px;
	background-color: #fff;
	color: $color;
	border-radius: 15px;
	width: 20px;
	height: 20px;
	font-size: 8pt;
	float: right;
	line-height: 19px;
	text-align: center;
	font-family: '__Titillium Web_5_semi_bold';
	margin-left: 30px;
	border-width:1px;
	border-style:solid;
	border-color:$color;
}



html, body {
  width: 100%;
  height: 100%;
}

body {
	background-color: white;
}

@font-face
{
	font-family: '__Titillium Web_5';
	src: url('../fonts/titillium-regular-webfont.eot');
	src: local('☺'), url('../fonts/titillium-regular-webfont.woff') format('woff'), url('../fonts/titillium-regular-webfont.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face
{
	font-family: '__Titillium Web_5_semi_bold';
	src: url('../fonts/titillium-semibold-webfont.eot');
	src: local('☺'), url('../fonts/titillium-semibold-webfont.woff') format('woff'), url('../fonts/titillium-semibold-webfont.svg') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face
{
	font-family: '__Titillium Web_5_bold';
	src: url('../fonts/titillium-semibold-webfont.eot');
	src: local('☺'), url('../fonts/titillium-semibold-webfont.woff') format('woff'), url('../fonts/titillium-semibold-webfont.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}

input[type="checkbox"]{
	display:none;
}

.kq_invisible{
	display: none;
}

/*Pour cacher les liens et bouton de google */
a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}

.gm-svpc{
	margin-top: 2px;
	margin-left: 2px;
	box-shadow: none !important;
}

.gmnoprint.gm-bundled-control{
	background: rgba(255, 255, 255, 1) none repeat scroll 0 0 !important;
	border-color: #c0c0c0;
	border-style: solid;
	border-width: 1px;
	height: 32px;
	width: 32px;
}

.poi{
	width: 100%;
}

.gmap3{
	float:left;
	display: inline-block;
}

.kq_accordion {
  display: inline-block;
  float: left;
  height: 100%;
}

.kq_sousCategorie, .kq_sousCategorie_selected{
	height: 30px;
	display : block;
}

.styleInfoWindow{
	width: 200px;
	overflow: hidden;
}

.kq_miniature, .kq_miniature2 {
	height: 18px;
}

.kq_sousCategorie label, .kq_sousCategorie_selected label, .kq_sousCategorie_unselected label {
	width: 110px; display: inline-block; text-overflow: ellipsis;
	white-space: nowrap; overflow: hidden; vertical-align: unset;
}


@media all and (max-width: 640px ) {
    body {
        background-color: #f0f0f0;
    }

    .kq_accordion {
        display: none;
        width: 100% !important;
    }

    .kq_accordion > dl:first-of-type {
        width: 100% !important;
    }

    .kq_accordion dl dt a {
        width: 100% !important;
    }


    #kq_resp_icon {
        display: inline-block !important;
        position: absolute;
        top: 1%;
        right: 2%;
        z-index: 1;
    }

}

