@import "style_commun.scss";

body {
	background-color: #efefef;
}

.gmnoprint.gm-bundled-control{
	margin-left: 5px !important;;
}

.gmap3{
	@include gmap(340px);
}

#poi{
	display: inline-block;
	float: left;
}

#kq_title{
	display: inline-block;
	height: 100%;
	vertical-align: top;
	color: #555555;
	max-height: 690px;
	overflow: auto;
	width: 95px;
}

#kq_liseret{
	display: inline-block;
	background-image: linear-gradient(to right, #7a91ab, $transport_color);
	width: 4px;
	height: 684px;
	margin-left: -4px;
}

.kq_catTitle{
	width: 78px;
	height: 76px;
	margin-right: 0px;
	background-color: #EFEFEF;
	font-family : '__Titillium Web_5_bold';
	font-size: 13px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center
}

.kq_logoCat, .kq_logoCat2, .kq_logoCat3{
	margin-left: auto;
	margin-right: auto;
	float: center;
	margin-bottom: 10px;
}


#kq_content {
	padding-bottom: 5px;
	display: inline-block;
	height: 100%;
	width: 240px;
	background-color: #EFEFEF;
	vertical-align: top;
	margin-left: -4px;
}

#catCommerce_et_ServiceTitle {

	.kq_active {
		color: $commerce_color;
	}

	.kq_open{
		background-color: $commerce_color;
		color: white;
	}
}

#catCultureTitle {
	.kq_active {
		color: $culture_color;
	}

	.kq_open{
		background-color: $culture_color;
		color: white;
	}
}
#catEducationTitle {
	.kq_active {
		color: $education_color;
	}

	.kq_open{
		background-color: $education_color;
		color: white;
	}
}

#catLoisirsTitle {
	.kq_active {
		color: $loisirs_color;
	}

	.kq_open{
		background-color: $loisirs_color;
		color: white;
	}
}

#catPetite_enfanceTitle {
	.kq_active {
		color: $enfance_color;
	}

	.kq_open{
		background-color: $enfance_color;
		color: white;
	}
}

#catSanteTitle {
	.kq_active {
		color: $sante_color;
	}

	.kq_open{
		background-color: $sante_color;
		color: white;
	}
}

#catService_PublicTitle {
	.kq_active {
		color: $serv_public_color;
	}

	.kq_open{
		background-color: $serv_public_color;
		color: white;
	}
}

#catTourismeTitle {
	.kq_active {
		color: $tourisme_color;
	}

	.kq_open{
		background-color: $tourisme_color;
		color: white;
	}
}

#catTransportTitle {
	.kq_active {
		color: $transport_color;
	}

	.kq_open{
		background-color: $transport_color;
		color: white;
	}
}

#RisquesTitle {
	.kq_active {
		color: $risques_color;
	}

	.kq_open{
		border-color: $risques_color;
		color: white;
	}
}

#Activites_sportivesTitle {
	.kq_active {
		color: $act_sport_color;
	}

	.kq_open{
		border-color: $act_sport_color;
		color: white;
	}
}

#Activites_recreativesTitle {
	.kq_active {
		color: $act_recr_color;
	}

	.kq_open{
		border-color: $act_recr_color;
		color: white;
	}
}

#Lieux_touristiquesTitle {
	.kq_active {
		color: $lieux_tour_color;
	}

	.kq_open{
		border-color: $lieux_tour_color;
		color: white;
	}
}

#FestivalTitle {
	.kq_active {
		color: $act_recr_color;
	}

	.kq_open{
		border-color: $act_recr_color;
		color: white;
	}
}

#Lieux_dinteretsTitle {
	.kq_active {
		color: $lieux_inter_color;
	}

	.kq_open{
		border-color: $lieux_inter_color;
		color: white;
	}
}

#kq_liseret[value="catCommerce_et_ServiceTitle"]{
	background-image:linear-gradient(to right, #6AB1C8, $commerce_color);
}

#kq_liseret[value="catCultureTitle"]{
	background-image:linear-gradient(to right, #B8A5C7, $culture_color);
}

#kq_liseret[value="catEducationTitle"]{
	background-image:linear-gradient(to right, #E47552, $education_color);
}

#kq_liseret[value="catLoisirsTitle"]{
	background-image:linear-gradient(to right, #ECB300, $loisirs_color);
}

#kq_liseret[value="catPetite_enfanceTitle"]{
	background-image:linear-gradient(to right, #2792CB, $enfance_color);
}

#kq_liseret[value="catSanteTitle"]{
	background-image:linear-gradient(to right, #5DB7AD, $sante_color);
}

#kq_liseret[value="catService_PublicTitle"]{
	background-image:linear-gradient(to right, #D67999, $serv_public_color);
}

#kq_liseret[value="catTourismeTitle"]{
	background-image:linear-gradient(to right, #87B76B, $tourisme_color);
}

#kq_liseret[value="catTransportTitle"]{
	background-image:linear-gradient(to right, #7A91AB, $transport_color);
}

#kq_liseret[value="catRisquesTitle"]{
	background-image:linear-gradient(to right, $risques_color, $risques_color);
}

#kq_liseret[value="catActivites_sportivesTitle"]{
	background-image:linear-gradient(to right, $act_sport_color, $act_sport_color);
}

#kq_liseret[value="catActivites_recreativesTitle"]{
	background-image:linear-gradient(to right, $act_recr_color, $act_recr_color);
}

#kq_liseret[value="catLieux_touristiquesTitle"]{
	background-image:linear-gradient(to right, $lieux_tour_color, $lieux_tour_color);
}

#kq_liseret[value="catFestivalTitle"]{
	background-image:linear-gradient(to right, $festival_color, $festival_color);
}

#kq_liseret[value="catLieux_dinteretsTitle"]{
	background-image:linear-gradient(to right, $lieux_inter_color, $lieux_inter_color);
}

.kq_sousCategorie{
	padding-right: 10px;
	height: 33px;
	background-color: #F0F0F0;
	font-size: 14px;
	color: #555555;
	font-family: '__Titillium Web_5';
}

.kq_sousCategorie_selected{
	padding-right: 10px;
	height: 33px;
	font-size: 14px;
	color: #555555;
	font-family: '__Titillium Web_5';
}

#catCommerce_et_ServiceContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $commerce_color;
	}

	.kq_open{
		background-color: $commerce_color;
		color: white;
	}
}

#catCultureContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $culture_color;
	}

	.kq_open{
		background-color: $culture_color;
		color: white;
	}
}

#catEducationContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $education_color;
	}

	.kq_open{
		background-color: $education_color;
		color: white;
	}
}

#catLoisirsContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $loisirs_color;
	}

	.kq_open{
		background-color: $loisirs_color;
		color: white;
	}
}

#catPetite_enfanceContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $enfance_color;
	}

	.kq_open{
		background-color: $enfance_color;
		color: white;
	}
}

#catSanteContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $sante_color;
	}

	.kq_open{
		background-color: $sante_color;
		color: white;
	}
}

#catService_PublicContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $serv_public_color;
	}

	.kq_open{
		background-color: $serv_public_color;
		color: white;
	}
}

#catTourismeContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $tourisme_color;
	}

	.kq_open{
		background-color: $tourisme_color;
		color: white;
	}
}

#catTransportContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $transport_color;
	}

	.kq_open{
		background-color: $transport_color;
		color: white;
	}
}

#catRisquesContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $risques_color;
	}

	.kq_open{
		background-color: $risques_color;
		color: white;
	}
}

#catActivites_sportivesContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $act_sport_color;
	}

	.kq_open{
		background-color: $act_sport_color;
		color: white;
	}
}

#catActivites_recreativesContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $act_recr_color;
	}

	.kq_open{
		background-color: $act_recr_color;
		color: white;
	}
}

#catLieux_touristiquesContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $lieux_tour_color;
	}

	.kq_open{
		background-color: $lieux_tour_color;
		color: white;
	}

}

#catFestivalContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $act_recr_color;
	}

	.kq_open{
		background-color: $act_recr_color;
		color: white;
	}
}

#catLieux_dinteretsContent {
	.kq_sousCategorie_selected .kq_miniature2 {
		background-color: $lieux_inter_color;
	}

	.kq_open{
		background-color: $lieux_inter_color;
		color: white;
	}
}


.nombrePresent{
	margin-top: 8px;
	background-color: #C0C0C0;
	color: #fff;
	border-radius: 15px;
	width: 17px;
	height: 17px;
	font-size: 7pt;
	float: right;
	line-height: 15.5px;
	text-align: center;
	font-family: '__Titillium Web_5_semi_bold';
}

.nombrePresent2{
	margin-top: 8px;
	border-radius: 15px;
	width: 16px;
	height: 16px;
	font-size: 8pt;
	float: right;
	line-height: 15.5px;
	text-align: center;
	font-family: '__Titillium Web_5_semi_bold';
	border-width:1px;
	border-style:solid;
}


#catCommerce_et_ServiceContent .nombrePresent2 {
	@include nbrPresentBouquet('white', $commerce_color, $commerce-color);
}

.kq_open #catCommerce_et_ServiceContent .nombrePresent2 {
	@include nbrPresentBouquet($commerce_color, 'white', $commerce-color);
}

#catCultureContent .nombrePresent2 {
	@include nbrPresentBouquet($culture_color, 'white', $culture_color);
}

.kq_open #catCultureContent .nombrePresent2 {
	@include nbrPresentBouquet('white', $culture_color, $culture_color);
}

#catEducationContent .nombrePresent2 {
	@include nbrPresentBouquet($education_color, 'white', $education_color);
}

.kq_open #catEducationContent .nombrePresent2 {
	@include nbrPresentBouquet('white', $education_color, $education_color);
}

#catLoisirsContent .nombrePresent2 {
	@include nbrPresentBouquet($loisirs_color, 'white', $loisirs_color);
}

.kq_open #catLoisirsContent .nombrePresent2 {
	@include nbrPresentBouquet('white', $loisirs_color, $loisirs_color);
}

#catPetite_enfanceContent .nombrePresent2 {
	@include nbrPresentBouquet($enfance_color, 'white', $enfance_color);
}

.kq_open #catPetite_enfanceContent .nombrePresent2 {
	@include nbrPresentBouquet('white', $enfance_color, $enfance_color);
}

#catSanteContent .nombrePresent2 {
	@include nbrPresentBouquet($sante_color, 'white', $sante_color);
}

.kq_open #catSanteContent .nombrePresent2 {
	@include nbrPresentBouquet('white', $sante_color, $sante_color);
}

#catService_PublicContent .nombrePresent2 {
	@include nbrPresentBouquet($serv_public_color, 'white', $serv_public_color);
}

.kq_open #catService_PublicContent .nombrePresent2 {
	@include nbrPresentBouquet('white', $serv_public_color, $serv_public_color);
}

#catTourismeContent .nombrePresent2 {
	@include nbrPresentBouquet($lieux_tour_color, 'white', $lieux_tour_color);
}

.kq_open #catTourismeContent .nombrePresent2 {
	@include nbrPresentBouquet('white', $lieux_tour_color, $lieux_tour_color);
}

#catTransportContent .nombrePresent2 {
	@include nbrPresentBouquet($transport_color, 'white', $transport_color);
}

.kq_open #catTransportContent .nombrePresent2 {
	@include nbrPresentBouquet($transport_color, 'white', $transport_color);
}

.kq_miniature{
	margin-top: 7px;
	padding-left: 10px;
	padding-right: 8px;
	margin-right: 2px;
	padding-bottom: 10px;
	vertical-align: middle;
}

.kq_miniature2{
	padding-top: 7px;
	padding-left: 10px;
	padding-right: 8px;
	padding-bottom: 10px;
	margin-right: 2px;
	vertical-align: middle;
}

.kq_fleche{
	float:right;
	margin-top: 12px;
	margin-left: 9px;
}

.detailPoi{
	overflow: auto;
	padding-left: 10px;
	padding-right: 10px;
	background-color: white;
	width: 100%;
}

#catCommerce_et_ServiceContent .detailPoi{
	@include heightDetails(330px);
}

#catCultureContent .detailPoi{
	@include heightDetails(99px);
}

#catEducationContent .detailPoi{
	@include heightDetails(165px);
}

#catLoisirsContent .detailPoi{
	@include heightDetails(132px);
}

#catPetite_enfanceContent .detailPoi{
	@include heightDetails(66px);
}

#catSanteContent .detailPoi{
	@include heightDetails(132px);
}

#catService_PublicContent .detailPoi{
	@include heightDetails(264px);
}

#catTourismeContent .detailPoi{
	@include heightDetails(99px);
}

#catTransportContent .detailPoi{
	@include heightDetails(462px);
}

.kq_info{
	margin-top: 10px;
	padding-bottom: 10px;
	width: 220px;
	background-color: white;
	border-bottom-color: #555555;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.nom{
	font-size: 10pt;
	font-family: '__Titillium Web_5_semi_bold';
	color: #555555;
	margin-bottom: 2px;
}

.kq_info1{
	font-size: 9pt;
	font-family: '__Titillium Web_5';
	color: #555555;
}

.kq_info2{
	font-size: 9pt;
	font-family: '__Titillium Web_5';
	color: #555555;
}

.kq_info3{
	font-size: 9pt;
	font-family: '__Titillium Web_5';
	color: #555555;
}

@media all and (max-width: 960px) {
	#kq_content {
		display: none;
		position: absolute;
		top: 0;
		left: 85px;
		z-index: 1;
	}

	.gmap3 {
		width: calc(100% - 100px);
	}

	.detailPoi {
		padding: 0 !important;
	}

	.kq_info {
		padding-left: 5px;
	}
}

@media all and (max-width: 640px ) {
	.kq_miniature, .kq_miniature2 {
		display: none;
	}

	#kq_content {
		width: 65%;
	}

	.kq_info {
		width: calc(100% - 5px);
	}

	.kq_sousCategorie_selected {
		padding-left: 5px;
	}

	.kq_sousCategorie_selected label {
		padding-top: 8px;
		vertical-align: middle;
	}
}

